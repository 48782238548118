import * as PIXI from 'pixi.js';

import { Variables } from '../../config';

export const buyFeatureTitleStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fill: '#ff3d00',
  fontSize: 72,
  fontWeight: '900',
  stroke: '#ffd74a',
  strokeThickness: 8,
  lineHeight: 68,
  miterLimit: 1,
  padding: 5,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const totalCostTextStyle = {
  fontSize: 42,
  fontFamily: Variables.FONT_FAMILY,
  fill: '#722200',
};

export const totalCostTextAmountStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fill: '#722200',
  fontSize: 88,
  fontWeight: '900',
  stroke: '#ffd74a',
  strokeThickness: 8,
  lineHeight: 68,
  miterLimit: 1,
  padding: 5,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const betTextStyle = {
  fontSize: 42,
  fontFamily: Variables.FONT_FAMILY,
  fill: '#722200',
};

export const betValueStyle = new PIXI.TextStyle({
  fontFamily: Variables.FONT_FAMILY,
  fontSize: 56,
  fontWeight: '900',
  fill: '#ffd74a',
  miterLimit: 1,
  padding: 5,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});
