import { useReactiveVar } from '@apollo/client';
import { TextMetrics, TextStyle } from 'pixi.js';
import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  infoFeatureBuyDescs,
  infoFeatureFreeSpinDescs,
  infoFeatureMultiplierWildsDescs,
  infoFeaturePickEmBonusDescs,
} from '../../config/gameRules';
import { setSlotConfig } from '../../gql/cache';
import type { ISlotConfig } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const FreeSpinFeature: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['feature']}>
      <h1 className={styles['subtitle']}>{t('infoFeatureFreeSpinTitle')}</h1>
      <div className={`${styles['row']}`}>
        <div className={`${styles['col']}`}>
          {infoFeatureFreeSpinDescs.map((keyName) => (
            <p className={`${styles['p']}`} key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
          ))}
        </div>
      </div>
    </section>
  );
};

const PickeEmBonus: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['feature']}>
      <h1 className={styles['subtitle']}>{t('infoFeaturePickEmBonusTitle')}</h1>
      <div className={`${styles['row']}`}>
        <div className={`${styles['col']}`}>
          {infoFeaturePickEmBonusDescs.map((keyName) => (
            <p className={`${styles['p']}`} key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
          ))}
        </div>
      </div>
    </section>
  );
};

const MultiplierWilds: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['feature']}>
      <h1 className={styles['subtitle']}>{t('infoFeatureMultiplierWildsTitle')}</h1>
      <div className={`${styles['row']}`}>
        <div className={`${styles['col']}`}>
          {infoFeatureMultiplierWildsDescs.map((keyName) => (
            <p className={styles['p']} key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
          ))}
        </div>
      </div>
    </section>
  );
};

const BuyAFeature: React.VFC = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);
  const [fontSize, setFontSize] = useState(34);

  const calcFontSize = (fontSize: string | number, boxWidth: number, textWidth: number) => {
    if (boxWidth >= textWidth) {
      return Number(fontSize);
    }

    return Math.floor(Number(fontSize) * (boxWidth / textWidth));
  };

  useEffect(() => {
    const largestWord = t('buyFeatureBtn')
      .split('\n')
      .reduce((a, b) => (a.length > b.length ? a : b));
    if (largestWord.length > 6) {
      setFontSize(28 - (largestWord.length - 6) * 2);
    }
  }, []);

  useEffect(() => {
    if (ref.current) {
      const style = new TextStyle({
        fontSize: 28,
        fontWeight: '700',
      });
      const text1Width = TextMetrics.measureText(t('infoFeatureBuyText1'), style).width;
      const text2Width = TextMetrics.measureText(t('infoFeatureBuyText2'), style).width;
      const boxWidth = ref.current.clientWidth - 15;

      if (text1Width > text2Width) {
        const fontSize = calcFontSize(style.fontSize, boxWidth, text1Width);

        ref.current.style.fontSize = `${fontSize}px`;
      } else {
        const fontSize = calcFontSize(style.fontSize, boxWidth, text2Width);

        ref.current.style.fontSize = `${fontSize}px`;
      }
    }
  }, [t]);

  return (
    <section className={styles['feature']}>
      <h1 className={styles['subtitle']}>{t('infoFeatureBuyTitle')}</h1>
      <div className={styles['buy_feature']} ref={ref}>
        <img
          className={styles['buy_feature__img']}
          draggable="false"
          src={Resources.getSource(ResourceTypes.buyFeatureBtn)}
          alt="buy-a-feature"
        />
        <p
          style={{ fontSize }}
          className={styles['buy_feature__text']}
          dangerouslySetInnerHTML={{ __html: t('buyFeatureBtn').replace(/\n/g, '<br>') }}
        />
      </div>
      <div className={`${styles['p']}`}>
        {infoFeatureBuyDescs.map((keyName) => (
          <div
            key={keyName}
            dangerouslySetInnerHTML={{
              __html: t(keyName, {
                multiplier: '134',
              }),
            }}
          />
        ))}
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  const { t } = useTranslation();
  const { isBuyFeatureEnabled } = useReactiveVar<ISlotConfig>(setSlotConfig);

  return (
    <div className={styles['gameRules']}>
      <h1 className={styles['title']}>{t('infoTabFeatures')}</h1>
      <MultiplierWilds />
      <PickeEmBonus />
      <FreeSpinFeature />
      {isBuyFeatureEnabled ? <BuyAFeature /> : ''}
    </div>
  );
};

export default Features;
