import * as PIXI from 'pixi.js';
import { isMobile } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode, PopupOpeningTypes } from '../../global.d';
import { setBrokenGame, setIsAutoSpins } from '../../gql';
import { setBrokenPickem, setCurrentBonus, setGameMode, setIsPopupOpeningInProgress } from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import {
  isFreeSpinMode,
  isMobileLandscape,
  isMobilePortrait,
  isPickemMode,
  isTabletLandscape,
  isTabletPortrait,
} from '../../utils';
import {
  FEATURE_BTN_CONTAINER_POSITION_X,
  FEATURE_BTN_CONTAINER_POSITION_Y,
  FEATURE_BTN_HEIGHT,
  FEATURE_BTN_HEIGHT_MOBILE,
  FEATURE_BTN_TEXT_FIT_TO_WIDTH,
  FEATURE_BTN_WIDTH,
  FEATURE_BTN_WIDTH_MOBILE,
  buyFeatureTextStyle,
  eventManager,
} from '../config';

class BuyFeatureBtn extends PIXI.Container {
  private btn: PIXI.Sprite;

  private text: PIXI.Text;

  isDisabled: boolean;

  screenWidth = 0;

  screenHeight = 0;

  get isMobileTabletPortrait(): boolean {
    return isMobile.any && this.screenHeight > this.screenWidth;
  }

  get isFreeRoundBonus(): boolean {
    return this.isDisabled && setCurrentBonus().gameMode === GameMode.FREE_ROUND_BONUS && setCurrentBonus().isActive;
  }

  constructor() {
    super();
    this.x = FEATURE_BTN_CONTAINER_POSITION_X;
    this.y = FEATURE_BTN_CONTAINER_POSITION_Y;
    this.isDisabled = false;
    this.visible = !((setBrokenGame() && setGameMode() === GameMode.FREE_SPINS) || setBrokenPickem());
    this.btn = this.initBuyFeatureBtn();
    this.addChild(this.btn);

    this.text = this.initBuyFeatureText();
    this.addChild(this.text);
    this.width = this.isMobileTabletPortrait ? FEATURE_BTN_WIDTH_MOBILE : FEATURE_BTN_WIDTH;
    this.height = this.isMobileTabletPortrait ? FEATURE_BTN_HEIGHT_MOBILE : FEATURE_BTN_HEIGHT;
    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });
    eventManager.on(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, (isVisible: boolean) => (this.visible = !isVisible));

    eventManager.on(EventTypes.CHANGE_MODE, (data) => {
      this.handleDisable(false);
      if (isPickemMode(data.mode) || isFreeSpinMode(data.mode)) {
        this.visible = false;
      } else {
        this.visible = true;
      }
    });
    eventManager.on(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.on(EventTypes.START_MODE_CHANGE_FADE, () => {
      this.handleDisable(true);
    });
  }

  private initBuyFeatureBtn() {
    const btn = new PIXI.Sprite(
      PIXI.Texture.from(this.isMobileTabletPortrait ? ResourceTypes.buyFeatureBtnMobile : ResourceTypes.buyFeatureBtn),
    );
    btn.width = this.isMobileTabletPortrait ? FEATURE_BTN_WIDTH_MOBILE : FEATURE_BTN_WIDTH;
    btn.height = this.isMobileTabletPortrait ? FEATURE_BTN_HEIGHT_MOBILE : FEATURE_BTN_HEIGHT;
    btn.x = 0;
    btn.y = 0;
    btn.interactive = true;
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());

    btn.addListener('mouseover', () => {
      if (!this.isDisabled) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.btn.texture = PIXI.Texture.from(
          this.isMobileTabletPortrait ? ResourceTypes.buyFeatureBtnHoverMobile : ResourceTypes.buyFeatureBtnHover,
        );
        this.btn.name = this.isMobileTabletPortrait ? 'mobile' : 'desktop';
        this.text.style = { ...buyFeatureTextStyle, fill: '#ffffff' };
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(
          this.isMobileTabletPortrait ? ResourceTypes.buyFeatureBtnMobile : ResourceTypes.buyFeatureBtn,
        );
        this.btn.name = this.isMobileTabletPortrait ? 'mobile' : 'desktop';
        this.text.style = buyFeatureTextStyle;
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(
          this.isMobileTabletPortrait ? ResourceTypes.buyFeatureBtnPressedMobile : ResourceTypes.buyFeatureBtnPressed,
        );
        this.btn.name = this.isMobileTabletPortrait ? 'mobile' : 'desktop';
        this.text.style = buyFeatureTextStyle;
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(
          this.isMobileTabletPortrait ? ResourceTypes.buyFeatureBtnMobile : ResourceTypes.buyFeatureBtn,
        );
        this.btn.name = this.isMobileTabletPortrait ? 'mobile' : 'desktop';
        this.text.style = buyFeatureTextStyle;
      }
    });
    return btn;
  }

  private initBuyFeatureText(): PIXI.Text {
    const text = new PIXI.Text(
      i18n.t(this.isMobileTabletPortrait ? 'buyFeatureBtnMobile' : 'buyFeatureBtn') as string,
      buyFeatureTextStyle,
    );
    text.anchor.set(0.5, 0.5);

    return text;
  }

  private onClick(): void {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE && !setIsAutoSpins()) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.BUY_FEATURE_POPUP);
      AudioApi.play({ type: ISongs.BuyButton });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.SHOW_BACKDROP);

      setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  }

  private handleDisable(disable: boolean): void {
    if (this.isAutoSpinInProgress || this.isFreeRoundBonus) return;

    this.isDisabled = disable;
    if (disable) {
      this.btn.texture = PIXI.Texture.from(
        this.isMobileTabletPortrait ? ResourceTypes.buyFeatureBtnNotActiveMobile : ResourceTypes.buyFeatureBtnNotActive,
      );
      this.btn.name = this.isMobileTabletPortrait ? 'mobile' : 'desktop';

      this.text.style = {
        ...buyFeatureTextStyle,
        fill: '#a89c97',
        fontSize: this.btn.name === 'desktop' ? 32 : 24,
        lineHeight: this.btn.name === 'desktop' ? 39 : 25,
      };
    } else {
      this.btn.texture = PIXI.Texture.from(
        this.isMobileTabletPortrait ? ResourceTypes.buyFeatureBtnMobile : ResourceTypes.buyFeatureBtn,
      );
      this.btn.name = this.isMobileTabletPortrait ? 'mobile' : 'desktop';

      this.text.style = {
        ...buyFeatureTextStyle,
        fontSize: this.btn.name === 'desktop' ? 32 : 24,
        lineHeight: this.btn.name === 'desktop' ? 39 : 25,
      };
    }
    if (this.isMobileTabletPortrait) {
      const textMaxWidth = FEATURE_BTN_TEXT_FIT_TO_WIDTH * 2;
      this.fitText(this.text, textMaxWidth - 20);
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  private updateButton(): void {
    const isMobileBtn = this.btn.name === 'mobile';
    if (this.isMobileTabletPortrait !== isMobileBtn || !this.btn.name) {
      if (this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(
          this.isMobileTabletPortrait
            ? ResourceTypes.buyFeatureBtnNotActiveMobile
            : ResourceTypes.buyFeatureBtnNotActive,
        );
      } else {
        this.btn.texture = PIXI.Texture.from(
          this.isMobileTabletPortrait ? ResourceTypes.buyFeatureBtnMobile : ResourceTypes.buyFeatureBtn,
        );
      }
      this.btn.width = this.isMobileTabletPortrait ? FEATURE_BTN_WIDTH_MOBILE : FEATURE_BTN_WIDTH;
      this.btn.height = this.isMobileTabletPortrait ? FEATURE_BTN_HEIGHT_MOBILE : FEATURE_BTN_HEIGHT;
      this.btn.name = this.isMobileTabletPortrait ? 'mobile' : 'desktop';
      this.text.text = i18n.t(this.isMobileTabletPortrait ? 'buyFeatureBtnMobile' : 'buyFeatureBtn') as string;
      this.text.style = {
        ...buyFeatureTextStyle,
        fontSize: this.isMobileTabletPortrait ? 24 : 32,
        lineHeight: this.isMobileTabletPortrait ? 25 : 39,
      };
      this.text.position.set(this.isMobileTabletPortrait ? 90 : 70, this.isMobileTabletPortrait ? 40 : 80);
      const fitWidth = this.isMobileTabletPortrait ? FEATURE_BTN_TEXT_FIT_TO_WIDTH * 3 : FEATURE_BTN_TEXT_FIT_TO_WIDTH;
      if (this.text.width > fitWidth) {
        this.text.scale.set(fitWidth / this.text.width);
      }
      this.width = this.isMobileTabletPortrait ? FEATURE_BTN_WIDTH_MOBILE : FEATURE_BTN_WIDTH;
      this.height = this.isMobileTabletPortrait ? FEATURE_BTN_HEIGHT_MOBILE : FEATURE_BTN_HEIGHT;
    }
  }

  private resize(width: number, height: number): void {
    this.screenHeight = height;
    this.screenWidth = width;
    this.updateButton();

    if (this.btn.name === 'desktop') {
      this.x = FEATURE_BTN_CONTAINER_POSITION_X;
      this.y = FEATURE_BTN_CONTAINER_POSITION_Y;
      this.text.style = { ...buyFeatureTextStyle, fontSize: 32, lineHeight: 39 };
    }

    if (this.btn.name === 'mobile') {
      if (width >= 1000) {
        this.scale.set(2);
        this.y = 420;
        this.x = -FEATURE_BTN_WIDTH_MOBILE;
      } else {
        this.scale.set(2);
        this.y = 0;
        this.x = -900;
      }
      this.text.style = { ...buyFeatureTextStyle, fontSize: 24, lineHeight: 25 };
    }

    if (isTabletLandscape(width, height)) {
      this.scale.set(1.4);
      this.x = -970;
      this.y = -90;
    }
    if (isTabletPortrait(width, height)) {
      this.scale.set(2);
      this.y = 420;
      this.x = -FEATURE_BTN_WIDTH_MOBILE;
    }
    if (isMobilePortrait(width, height)) {
      this.scale.set(2);
      this.y = 420;
      this.x = -FEATURE_BTN_WIDTH_MOBILE;
    }
    if (isMobileLandscape(width, height)) {
      this.scale.set(1.4);
      this.y = -120;
      this.x = -1000;
    }
    if (this.isDisabled) {
      this.text.style = { ...this.text.style, fill: '#a89c97' };
    }
    const textMaxWidth = FEATURE_BTN_TEXT_FIT_TO_WIDTH * 2;
    if (this.isMobileTabletPortrait) {
      this.fitText(this.text, textMaxWidth - 20);
    }
  }

  private fitText(textField: PIXI.Text, maxWidth: number) {
    let fontSize = Number(textField.style.fontSize);

    while (textField.width > maxWidth && Number(textField.style.fontSize) > 10) {
      fontSize -= 1;
      textField.style = {
        ...buyFeatureTextStyle,
        fontSize,
        lineHeight: fontSize,
      };
      textField.updateText(true);
    }
  }
}

export default BuyFeatureBtn;
