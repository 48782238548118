import { TEXT_GRADIENT } from 'pixi.js';

import { Variables } from '../../config';

export const titleStyle = {
  default: {
    fontSize: 60,
    letterSpacing: 0,
    align: 'center',
    fontFamily: Variables.FONT_FAMILY,
    fill: '#ff3d00',
    fontWeight: '900',
    stroke: '#ffd74a',
    strokeThickness: 8,
    lineHeight: 52,
    miterLimit: 1,
    padding: 5,
    whiteSpace: 'normal',
    wordWrapWidth: 140,
  },
  span: {
    fill: '#ff3d00',
  },
};

export const subtitleStyle = {
  fontSize: 68,
  fontFamily: Variables.FONT_FAMILY,
  dropShadow: true,
  dropShadowAngle: 1.5,
  dropShadowColor: '#c8000c',
  align: 'center',
  dropShadowDistance: 6,
  fill: [0xfff1c1, 0xfacd1d],
  fillGradientType: TEXT_GRADIENT.LINEAR_VERTICAL,
  fontWeight: '900',
  letterSpacing: 0,
  lineHeight: 90,
  miterLimit: 1,
  padding: 5,
  stroke: '#ff3d4a',
  strokeThickness: 10,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
};
export const additionalStyle = {
  fontSize: 40,
  fontFamily: Variables.FONT_FAMILY,
};

export const winTitleStyle = {
  fontSize: 80,
  fontFamily: Variables.FONT_FAMILY,
};

export const winSubtitleStyle = {
  fontSize: 120,
  fontFamily: Variables.FONT_FAMILY,
};

export const btnStyle = {
  default: {
    fontSize: 36,
    fontFamily: Variables.FONT_FAMILY,
    fill: '#FF7A00',
    align: 'center',
    fontWeight: '1000',
    miterLimit: 1,
  },
};
