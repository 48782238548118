import i18n from 'i18next';
import * as PIXI from 'pixi.js';
import { Sprite } from 'pixi.js';

import { EventTypes, FreeSpinsTitleProps } from '../../global.d';
import { updateTextScale } from '../../utils/utils';
import type Animation from '../animations/animation';
import { createZoomAnimation } from '../animations/helper';
import { eventManager } from '../config';

import { FREE_SPIN_ANIMATION_DELAY, FREE_SPIN_ANIMATION_LOOP, FREE_SPIN_ANIMATION_SCALE } from './config';
import { spinsStyle, textStyle } from './textStyles';

class FreeSpinsTitle extends Sprite {
  private text: PIXI.Text;

  private spins: PIXI.Text;

  private pulsAnimation: Animation | null = null;

  private spinsAmount: string;

  private currentSpin: string;

  constructor(props: FreeSpinsTitleProps) {
    super();
    this.y = -415;
    this.anchor.set(0.5);
    this.text = this.initText(props.text);
    this.spins = this.initSpins(props.spins, props.currentSpin);
    this.spinsAmount = props.spins;
    this.currentSpin = props.currentSpin;
    this.init();
    this.x = -((this.text.width + this.spins.width) / 2);
    eventManager.addListener(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE, (spins: string, curr: string): void =>
      this.handleUpdate(spins, curr),
    );
  }

  private init(): void {
    this.addChild(this.text);
    this.addChild(this.spins);
  }

  private initText(titleText?: string): PIXI.Text {
    const text = new PIXI.Text(i18n.t(titleText || '') as string, textStyle);
    updateTextScale(text, 400, 105);
    text.anchor.set(0, 0.5);
    text.y = 0;
    text.x = 0;

    return text;
  }

  private initSpins(spin?: string, currentSpin?: string): PIXI.Text {
    const spins = new PIXI.Text(`${currentSpin} / ${spin}`, spinsStyle);
    spins.x = this.text.width + spins.width / 2;
    spins.anchor.set(0.5, 0.5);

    return spins;
  }

  private handleUpdate(spins?: string, currentSpin?: string): void {
    if (this.currentSpin === currentSpin && this.spinsAmount === spins) {
      return;
    }

    this.currentSpin = currentSpin ?? this.currentSpin;
    this.spinsAmount = spins ?? this.spinsAmount;

    this.spins.text = `${currentSpin || this.currentSpin} / ${spins || this.spinsAmount}`;
    this.spins.x = this.text.width + this.spins.width / 2;
    this.pulsAnimation = createZoomAnimation(
      this.spins,
      FREE_SPIN_ANIMATION_SCALE,
      FREE_SPIN_ANIMATION_DELAY,
      FREE_SPIN_ANIMATION_LOOP,
    );
    this.pulsAnimation?.start();
  }

  public handleDestroy(): void {
    eventManager.removeListener(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE);
    this.destroy({ children: true, texture: true, baseTexture: true });
  }
}

export default FreeSpinsTitle;
