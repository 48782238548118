import i18n from 'i18next';
import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { setIsBuyFeaturePurchased } from '../../gql';
import { ResourceTypes } from '../../resources.d';
import { fitText, updateTextScale } from '../../utils/utils';
import ViewContainer from '../components/container';
import {
  FEATURE_POPUP_CONFIRM_CANCEL_BTN_HEIGHT,
  FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X,
  FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y,
  FEATURE_POPUP_CONFIRM_CANCEL_BTN_WIDTH,
  FEATURE_POPUP_CONFIRM_HEIGHT,
  FEATURE_POPUP_CONFIRM_OK_BTN_HEIGHT,
  FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_X,
  FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_Y,
  FEATURE_POPUP_CONFIRM_OK_BTN_WIDTH,
  FEATURE_POPUP_CONFIRM_POSITION_X,
  FEATURE_POPUP_CONFIRM_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TITLE_POSITION_X,
  FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_X,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_X,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y,
  FEATURE_POPUP_CONFIRM_WIDTH,
  eventManager,
} from '../config';

import { buyFeatureTitleStyle, totalCostTextAmountStyle, totalCostTextStyle } from './textStyles';

class BuyFeaturePopupConfirm extends ViewContainer {
  private popupBg: PIXI.Sprite;

  private coinAmount: number;

  private titleText: PIXI.Text;

  private totalCostText: PIXI.Text;

  private totalCostValue: PIXI.Text;

  private okBtn: PIXI.Sprite;

  private cancelBtn: PIXI.Sprite;

  private bee: PIXI.Sprite;

  constructor() {
    super();
    this.x = FEATURE_POPUP_CONFIRM_POSITION_X;
    this.y = FEATURE_POPUP_CONFIRM_POSITION_Y;
    this.visible = false;
    this.coinAmount = 1;
    this.bee = this.initBee();
    this.popupBg = this.initPopupBg();
    this.titleText = this.initTitle();
    this.totalCostText = this.initTotalCostText();
    this.totalCostValue = this.initTotalCostValue();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.interactive = true;
    this.init();
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.titleText);
    this.addChild(this.totalCostText);
    this.addChild(this.totalCostValue);
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
    this.addChild(this.bee);
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => this.handleClose());
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => this.handleClose());
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP, (totalCost: string, coinAmount: number) => {
      this.handleOpen(totalCost, coinAmount);
    });
    eventManager.on(EventTypes.FORCE_CLOSE_BUYFEATURE, () => {
      this.handleClose();
    });
  }

  private initPopupBg(): PIXI.Sprite {
    const popupBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeaturePopup));
    popupBg.height = FEATURE_POPUP_CONFIRM_HEIGHT;
    popupBg.width = FEATURE_POPUP_CONFIRM_WIDTH;

    return popupBg;
  }

  private initBee(): PIXI.Sprite {
    const bee = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureBee));
    bee.x = 850;
    bee.y = 200;
    return bee;
  }

  private initTitle(): PIXI.Text {
    const title = new PIXI.Text(i18n.t('buyFeatureConfirmTitle') as string, {
      ...buyFeatureTitleStyle,
      align: 'center',
      fontSize: 52,
    });
    title.y = FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y;
    title.x = FEATURE_POPUP_CONFIRM_TITLE_POSITION_X;
    updateTextScale(title, isMobile ? 820 : 850, isMobile ? 250 : 250);

    title.anchor.set(0.5, 0);
    fitText(title, this.popupBg.width - 200, {
      align: 'center',
    } as PIXI.TextStyle);

    return title;
  }

  private initTotalCostText(): PIXI.Text {
    const totalCostText = new PIXI.Text(i18n.t('buyFeatureTotalCost') as string, totalCostTextStyle);
    totalCostText.y = FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y;
    totalCostText.x = FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_X;
    updateTextScale(totalCostText, isMobile ? 820 : 850, isMobile ? 250 : 250);
    totalCostText.anchor.set(0.5, 0);

    return totalCostText;
  }

  private initTotalCostValue(): PIXI.Text {
    const totalCostValue = new PIXI.Text('0', totalCostTextAmountStyle);
    totalCostValue.anchor.set(0.5, 0);
    totalCostValue.y = FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y;
    totalCostValue.x = FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_X;

    return totalCostValue;
  }

  private initCancelBtn(): PIXI.Sprite {
    const cancelBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn));
    cancelBtn.width = FEATURE_POPUP_CONFIRM_CANCEL_BTN_WIDTH;
    cancelBtn.height = FEATURE_POPUP_CONFIRM_CANCEL_BTN_HEIGHT;
    cancelBtn.y = FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y;
    cancelBtn.x = FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X;
    cancelBtn.interactive = true;
    cancelBtn.on('click', () => {
      this.handleClose();
      eventManager.emit(EventTypes.SHOW_BACKDROP);
      AudioApi.play({ type: ISongs.SFX_UI_Close });
    });
    cancelBtn.on('touchstart', () => {
      this.handleClose();
      eventManager.emit(EventTypes.SHOW_BACKDROP);
      AudioApi.play({ type: ISongs.SFX_UI_Close });
    });

    cancelBtn.addListener('mouseover', () => {
      AudioApi.play({ type: ISongs.SFX_UI_Hover });
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnHover);
    });
    cancelBtn.addListener('mouseout', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn);
    });
    cancelBtn.addListener('mousedown', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnPressed);
    });
    cancelBtn.addListener('mouseup', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn);
    });

    return cancelBtn;
  }

  private initOkBtn(): PIXI.Sprite {
    const okBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn));
    okBtn.width = FEATURE_POPUP_CONFIRM_OK_BTN_WIDTH;
    okBtn.height = FEATURE_POPUP_CONFIRM_OK_BTN_HEIGHT;
    okBtn.y = FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_Y;
    okBtn.x = FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_X;
    okBtn.interactive = true;
    okBtn.on('click', () => {
      this.startBuyFeature();
    });
    okBtn.on('touchstart', () => {
      this.startBuyFeature();
    });
    okBtn.addListener('mouseover', () => {
      AudioApi.play({ type: ISongs.SFX_UI_Hover });
      this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnHover);
    });
    okBtn.addListener('mouseout', () => {
      this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn);
    });
    okBtn.addListener('mousedown', () => {
      this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnPressed);
    });
    okBtn.addListener('mouseup', () => {
      this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn);
    });

    return okBtn;
  }

  private startBuyFeature(): void {
    this.okBtn.interactive = false;
    this.cancelBtn.interactive = false;
    AudioApi.play({ type: ISongs.BuyButton });
    eventManager.emit(EventTypes.HANDLE_BUY_BONUS, this.coinAmount);
    setIsBuyFeaturePurchased(true);
  }

  public handleOpen(totalCost: string, coinAmount: number): void {
    this.visible = true;
    this.coinAmount = coinAmount;
    this.totalCostValue.text = totalCost;
    this.okBtn.interactive = true;
    this.cancelBtn.interactive = true;
  }

  public handleClose(): void {
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_CONFIRM_POPUP);
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_CONFIRM_POPUP_BG);
    this.visible = false;
  }
}

export default BuyFeaturePopupConfirm;
